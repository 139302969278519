import { ParsedUrlQuery } from 'querystring';

import { useCallback } from 'react';

import { useLocale } from '../../core/i18n/Locale';
import {
    BrandCmsRouteName,
    generateBrandUrl,
    generateFilePath,
    generateUrl,
    generateUrlMayNotExist,
    RouteName,
    RouteParams,
} from '../utilities/routing/routing';

export function useUrlGenerator() {
    const { locale: currentLocale } = useLocale();

    return {
        generateUrl: useCallback(
            (routeName: RouteName, params?: RouteParams, locale = currentLocale) => {
                return generateUrl(routeName, locale, params, true);
            },
            [currentLocale],
        ),
        generatePath: useCallback(
            (routeName: RouteName, params?: RouteParams, locale = currentLocale) => {
                return generateUrl(routeName, locale, params, false);
            },
            [currentLocale],
        ),
        generateUrlMayNotExist: useCallback(
            (routeName: RouteName, params?: RouteParams, locale = currentLocale) => {
                return generateUrlMayNotExist(routeName, locale, params, true);
            },
            [currentLocale],
        ),
        generatePathMayNotExist: useCallback(
            (routeName: RouteName, params?: RouteParams, locale = currentLocale) => {
                return generateUrlMayNotExist(routeName, locale, params, false);
            },
            [currentLocale],
        ),
        generateBrandUrl: useCallback(
            (routeName: BrandCmsRouteName, locale = currentLocale) => {
                return generateBrandUrl(routeName, locale);
            },
            [currentLocale],
        ),
        generateMobileApplicationUrl: useCallback((os: 'ios' | 'android') => {
            switch (os) {
                case 'ios':
                    return 'https://evaneos.app.link/evaneos-site-footer-ios';
                case 'android':
                    return 'https://evaneos.app.link/evaneos-site-footer-android';
            }
        }, []),
        generateFilePath: useCallback((routeName: RouteName, params?: ParsedUrlQuery) => {
            return generateFilePath(routeName, params);
        }, []),
    };
}
