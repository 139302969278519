export async function responseError(response: Response) {
    const message = `Response status ${response.status} from ${response.url}`;
    let cause;

    try {
        cause = await response.clone().json();
    } catch {
        try {
            cause = await response.clone().text();
        } catch {
            cause = 'Unknown error, failed to parse response body';
        }
    }

    const error = new Error(message, { cause });
    // @ts-expect-error - Property 'statusCode' does not exist on type 'Error'.
    error.statusCode = response.status;
    return error;
}
