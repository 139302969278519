import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum-slim';

export const browserLogger = datadogLogs.logger;

const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
const env = process.env.NEXT_PUBLIC_DATADOG_ENV;
const version = process.env.NEXT_PUBLIC_APP_VERSION;
const sessionSampleRateForRum = parseInt(
    process.env.NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE ?? '0',
    10,
);
const sessionSampleRateForLogs = parseInt(
    process.env.NEXT_PUBLIC_DATADOG_LOGS_SESSION_SAMPLE_RATE ?? '0',
    10,
);
const site = 'datadoghq.com';
const service = 'public-website-app';

if (applicationId && clientToken && env && version && !isNaN(sessionSampleRateForRum)) {
    datadogRum.init({
        applicationId,
        clientToken,
        site,
        service,
        env,
        version,
        sessionSampleRate: sessionSampleRateForRum,
        sessionReplaySampleRate: 0,
        trackUserInteractions: false,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        // Specify URLs to propagate trace headers for connection between RUM and backend trace
        // allowedTracingUrls: [{ match: 'https://example.com/api/', propagatorTypes: ['tracecontext'] }],
    });
}

if (clientToken && env && !isNaN(sessionSampleRateForLogs)) {
    datadogLogs.init({
        clientToken,
        site,
        forwardErrorsToLogs: false,
        sessionSampleRate: sessionSampleRateForLogs,
        service,
        env,
        version,
    });
}
