import config from '../../../configurations/assets.json';

export type MagazineRatio = keyof typeof config.magazinePicture.ratio;

export function formatByRatioAndWidth(ratio: MagazineRatio, width: number) {
    const configRatio = config.magazinePicture.ratio[ratio];

    const allWidth = Object.entries(configRatio).map((entries) => {
        return parseInt(entries[0], 10);
    });

    const sortWidth = allWidth.sort((a, b) => {
        return Math.abs(width - a) - Math.abs(width - b);
    }) as keyof typeof configRatio;

    return configRatio[sortWidth[0]];
}
