import { AspectRatioProps, Root as RadixAspectRatio } from '@radix-ui/react-aspect-ratio';

export const RATIO = {
    // Ordered from more vertical (taller)
    '3/4': 3 / 4,
    '4/5': 4 / 5,
    '7/8': 7 / 8,
    '1/1': 1,
    '5/4': 5 / 4,
    '4/3': 4 / 3,
    '16/10': 16 / 10,
    '16/9': 16 / 9,
    '21/9': 21 / 9,
    '3/1': 3,
    '10/3': 10 / 3,
    // .. to more horizontal (wider)
};

const ALLOWED_RATIOS = Object.values(RATIO);

type RatioProps = AspectRatioProps & {
    ratio: number;
};

export function Ratio({ children, ratio, ...props }: RatioProps) {
    if (!ALLOWED_RATIOS.includes(ratio)) {
        throw new Error(`Ratio: ${ratio} is not an allowed ratio`);
    }

    return (
        <RadixAspectRatio ratio={ratio} {...props}>
            {children}
        </RadixAspectRatio>
    );
}
