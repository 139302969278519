import cx from 'classnames';
import { forwardRef, HTMLProps } from 'react';
import { PolyRefFunction } from 'react-polymorphed';

import style from './Typography.module.css';

export const TYPOGRAPHY_DEFAULT_ELEMENT = 'span' as const;

type Props = HTMLProps<HTMLSpanElement> & { scale: Scale };

type Scale =
    | 'primary-xl-extra-bold'
    | 'primary-l-extra-bold'
    | 'primary-m-extra-bold'
    | 'primary-s-extra-bold'
    | 'primary-body-bold'
    | 'primary-body-regular'
    | 'primary-xs-bold'
    | 'primary-xs-regular'
    | 'primary-xxs-bold'
    | 'primary-xxs-regular'
    | 'secondary-xxl-bold'
    | 'secondary-xl-bold'
    | 'secondary-l-bold'
    | 'secondary-m-bold'
    | 'secondary-s-bold'
    | 'secondary-s-regular'
    | 'secondary-xs-regular'
    | 'secondary-xxs-regular';

const polyRef = forwardRef as PolyRefFunction;

export const Typography = polyRef<'span', Props>(
    ({ as: As = 'span', children, className, scale, ...props }, ref) => {
        return (
            <As
                data-testid="Typography"
                ref={ref}
                {...props}
                className={cx(className, {
                    [style['primary-xl-extra-bold']]: scale === 'primary-xl-extra-bold',
                    [style['primary-l-extra-bold']]: scale === 'primary-l-extra-bold',
                    [style['primary-m-extra-bold']]: scale === 'primary-m-extra-bold',
                    [style['primary-body-bold']]: scale === 'primary-body-bold',
                    [style['primary-body-regular']]: scale === 'primary-body-regular',
                    [style['primary-s-extra-bold']]: scale === 'primary-s-extra-bold',
                    [style['primary-xs-bold']]: scale === 'primary-xs-bold',
                    [style['primary-xs-regular']]: scale === 'primary-xs-regular',
                    [style['primary-xxs-bold']]: scale === 'primary-xxs-bold',
                    [style['primary-xxs-regular']]: scale === 'primary-xxs-regular',
                    [style['secondary-xxl-bold']]: scale === 'secondary-xxl-bold',
                    [style['secondary-xl-bold']]: scale === 'secondary-xl-bold',
                    [style['secondary-l-bold']]: scale === 'secondary-l-bold',
                    [style['secondary-m-bold']]: scale === 'secondary-m-bold',
                    [style['secondary-s-bold']]: scale === 'secondary-s-bold',
                    [style['secondary-s-regular']]: scale === 'secondary-s-regular',
                    [style['secondary-xs-regular']]: scale === 'secondary-xs-regular',
                    [style['secondary-xxs-regular']]: scale === 'secondary-xxs-regular',
                })}
            >
                {children}
            </As>
        );
    },
);
