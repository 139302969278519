import cx from 'classnames';
import React, {
    ComponentPropsWithoutRef,
    forwardRef,
    HTMLAttributeAnchorTarget,
    ReactElement,
    MouseEvent,
    SVGAttributes,
} from 'react';

import style from './Button.module.css';

export type ButtonProps = ComponentPropsWithoutRef<'a' | 'button'> & {
    disabled?: boolean;
    href?: string;
    iconPosition?: 'left' | 'right';
    Icon?: (props: SVGAttributes<SVGElement>) => ReactElement;
    isFullWidth?: boolean;
    obfuscate?: boolean;
    onClick?: (event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => void;
    priority?: 'primary' | 'secondary' | 'tertiary' | 'text' | 'secondaryNew';
    rel?: string;
    reversed?: boolean;
    target?: HTMLAttributeAnchorTarget;
    alert?: 'information' | 'success' | 'warning' | 'error';
    type?: 'button' | 'submit' | 'reset';
};

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            href: propsHref,
            Icon,
            iconPosition = 'left',
            isFullWidth,
            obfuscate,
            onClick,
            priority = 'primary',
            reversed = false,
            alert,
            ...props
        },
        ref,
    ) => {
        const hasIcon = !!Icon;
        const HTMLTag = propsHref && !obfuscate ? 'a' : 'button';
        const href = obfuscate ? undefined : propsHref;

        return (
            <HTMLTag
                {...props}
                // @ts-expect-error - Property 'ref' is not included in the HTMLTag
                ref={ref}
                className={cx(style.button, 'font-body-bold', className, {
                    [style.primary]: priority === 'primary',
                    [style.secondary]: priority === 'secondary',
                    [style.secondaryNew]: priority === 'secondaryNew',
                    [style.tertiary]: priority === 'tertiary',
                    [style.text]: priority === 'text',
                    [style.fullWidth]: isFullWidth,
                    [style.iconLeft]: iconPosition === 'left' && hasIcon,
                    [style.iconRight]: iconPosition === 'right' && hasIcon,
                    [style.isReversed]: reversed,
                    [style.isDisabled]: props.disabled,
                    [style.alertInformation]: alert === 'information',
                    [style.alertSuccess]: alert === 'success',
                    [style.alertWarning]: alert === 'warning',
                    [style.alertError]: alert === 'error',
                })}
                href={href}
                onClick={(event) => {
                    if (typeof onClick === 'function') {
                        onClick(event);
                    }

                    if (obfuscate && propsHref) {
                        if (props.target === '_blank' || event.ctrlKey) {
                            return window.open(propsHref, '_blank');
                        }
                        window.location.href = propsHref;
                    }
                }}
            >
                {iconPosition === 'left' && Icon ? <Icon className={style.icon} /> : null}
                {props.children}
                {iconPosition === 'right' && Icon ? <Icon className={style.icon} /> : null}
            </HTMLTag>
        );
    },
);

Button.displayName = 'Button';
