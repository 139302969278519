export class NotFoundResponse {
    public static status = 404;

    public static pageProps() {
        return {
            notFound: true,
        } as const;
    }

    public static error(message: string, options?: ErrorOptions) {
        return new NotFoundResponse.Error(message, options);
    }

    public static Error = class NotFound extends Error {
        constructor(message = '404 Not Found', options?: ErrorOptions) {
            super(message, options);

            this.name = 'NotFound';

            Object.setPrototypeOf(this, NotFound.prototype);

            if (Error.captureStackTrace) {
                Error.captureStackTrace(this, NotFound);
            }
        }
    };
}
