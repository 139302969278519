import * as querystring from 'querystring';
import { ParsedUrlQuery } from 'querystring';

import brandCmsRoutesConfig from '../../../configurations/brandCmsRoutes.json';
import routesConfig from '../../../configurations/routes.json';
import { Locale } from '../../../core/i18n/Locale';
import { getSiteByLocale } from '../../../core/Site/Site';
import { Currency } from '../../../resources/currency/domain/types/currency';

export type RouteParams = NodeJS.Dict<RouteParamValue>;
export type RouteParamValue = string | number | string[];
export type BrandCmsRouteName = keyof typeof brandCmsRoutesConfig;
export type Route = RouteSingle | RouteLocalized;
export type RouteSingle = {
    isFallbackRoute?: true;
    path: string;
    filePath?: string;
};
export type RouteLocalized = {
    isFallbackRoute?: true;
    path: RoutePathLocalized;
    filePath?: string;
};
export type RoutePathLocalized = {
    [key: string]: string;
};
export type RoutesConfig = {
    [key in RouteName]: Route;
};
export type RouteName = keyof typeof routesConfig;

const BRAND_CMS_URL = 'https://bettertrips.evaneos.com';
const TCARE_TOOLS_URL = 'https://tcare-tools.services.evaneos.com';

export const PATH_PARAM_PATTERN = ':([a-zA-Z]+)';

function allRoutes() {
    return routesConfig as RoutesConfig;
}

export function isLocalizedRoute(route: Route) {
    return typeof route.path === 'object';
}

export function generateUrl(
    routeName: RouteName,
    locale: Locale,
    params: RouteParams = {},
    absolute = false,
) {
    const routes = allRoutes();
    const site = getSiteByLocale(locale);
    const { lang } = site;
    const queryParams = paramsWithoutUndefined(params);
    let { path } = routes[routeName];

    if (typeof path === 'object') {
        path = path[lang];
    }

    if (!path) {
        throw new Error(`Route ${routeName} doesnt exist for lang ${lang}`);
    }

    let url = path.replace(new RegExp(PATH_PARAM_PATTERN, 'g'), (match, pathParamName) => {
        if (!(pathParamName in params)) {
            throw new Error(`Missing path param ${match} to generate ${routeName} route url`);
        }

        const value = params[pathParamName];

        if (value === undefined || value === null || value === '' || Array.isArray(value)) {
            let valueForErrorMessage = String(value);
            if (Array.isArray(value)) valueForErrorMessage = `[${value}]`;
            if (value === '') valueForErrorMessage = 'an empty string';
            throw new Error(
                `Invalid path param ${match} (value is ${valueForErrorMessage}) to generate ${routeName} route url`,
            );
        }

        delete queryParams[pathParamName];

        return value.toString();
    });

    if (Object.keys(queryParams).length) {
        url = `${url}?${buildURLQuery(queryParams)}`;
    }

    if (absolute) {
        url = site.url + url;
    }

    return url;
}

export function generateFilePath(routeName: RouteName, params?: ParsedUrlQuery) {
    const routes = allRoutes();
    const { filePath } = routes[routeName];
    if (!filePath) {
        throw new Error(`Route ${routeName} filePath is undefined`);
    }

    const searchParams = new URLSearchParams(querystring.stringify(params));
    const query = searchParams.toString();

    if (query) {
        return `${filePath}?${query}`;
    }
    return filePath;
}

export function generateUrlMayNotExist(
    routeName: RouteName,
    locale: Locale,
    params?: RouteParams,
    absolute?: boolean,
): string | null {
    try {
        return generateUrl(routeName, locale, params, absolute);
    } catch {
        return null;
    }
}

export function generateBrandUrl(routeName: BrandCmsRouteName, locale: Locale): string {
    const route = brandCmsRoutesConfig[routeName];
    const slug = Object.entries(route).reduce<string | null>((slug, [entryLocale, entrySlug]) => {
        if (entryLocale === locale) {
            return entrySlug;
        }
        return slug;
    }, null);

    if (!slug) {
        return BRAND_CMS_URL;
    }
    return BRAND_CMS_URL + slug;
}

export function getTcareToolsUrl() {
    return TCARE_TOOLS_URL;
}

type TcareToolsTripFormParams = {
    destinationSlug: string;
    agencyId: number | null;
    itineraryId: number | null;
    gammesList: Record<string, string> | null;
    hostingList: Record<string, string> | null;
    guideList: Record<string, string> | null;
    siteId: number;
    currency: Currency;
};

export function getTcareToolsTripFormUrl({
    destinationSlug,
    agencyId,
    itineraryId,
    gammesList,
    hostingList,
    guideList,
    siteId,
    currency,
}: TcareToolsTripFormParams): string {
    const baseUrl = getTcareToolsUrl();
    const queryParams = new URLSearchParams();

    queryParams.append('destination', destinationSlug);
    queryParams.append('agencyId', agencyId ? agencyId.toString() : '');
    queryParams.append('itineraryId', itineraryId ? itineraryId.toString() : '');
    queryParams.append('rangeList', JSON.stringify(gammesList ?? {}));
    queryParams.append('hostingList', JSON.stringify(hostingList ?? {}));
    queryParams.append('guideList', JSON.stringify(guideList ?? {}));
    queryParams.append('siteId', siteId.toString());
    queryParams.append('currency', currency);

    return `${baseUrl}/quoteForm?${queryParams.toString()}`;
}

function paramsEntriesWithoutUndefined(
    paramsEntries: Array<[string, RouteParamValue | undefined]>,
): [string, RouteParamValue][] {
    return paramsEntries.filter(
        (param: [string, RouteParamValue | undefined]): param is [string, RouteParamValue] =>
            param[1] !== undefined,
    );
}

function paramsWithoutUndefined(params: RouteParams): { [key: string]: RouteParamValue } {
    return paramsEntriesWithoutUndefined(Object.entries(params)).reduce(
        (filteredParams, [key, value]) => {
            return {
                ...filteredParams,
                [key]: value,
            };
        },
        {},
    );
}

function buildURLQuery(params: RouteParams) {
    return paramsEntriesWithoutUndefined(Object.entries(params))
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return value
                    .map((val) => {
                        return `${key}=${encodeURIComponent(val)}`;
                    })
                    .join('&');
            }
            return `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
}
