import { PickDomain } from '../../../../shared/utilities/types/PickDomain';
import { Itinerary } from '../../domain/types/Itinerary';

type NormalizedWishListItineraryFromPyrite = PickDomain<Itinerary, 'id'>;

export type WishListItineraryFromPyrite = {
    id: number;
    title: string;
    price: string;
    duration: number;
    url: string;
    pois: Array<string>;
    picture: {
        id: number;
        copyright: string;
        legend: string;
        width: number;
        height: number;
    } | null;
    agent: {
        name: string;
        facePictureId: number;
    };
    destination: {
        id: number;
        name: string;
        preposition: string;
        slug: string;
        pageUrl: string;
    };
    travelTypes: Array<{
        name: string;
        slug: string;
    }>;
};

export function normalizeWishListItinerary(
    itinerary: WishListItineraryFromPyrite,
): NormalizedWishListItineraryFromPyrite {
    return {
        id: itinerary.id,
    };
}
