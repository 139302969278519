import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { logger } from '../../../core/Log/logger';
import { trackCustomEvent } from '../../../core/Tracking/GoogleAnalytics';
import { getUserHash } from '../../../resources/user/domain/operations/getUserHash';
import { setUserTrackingIdCookie } from '../../../resources/user/domain/operations/setUserTrackingIdCookie';
import { User } from '../../../resources/user/domain/types/User';
import { useUrlGenerator } from '../../hooks/useUrlGenerator';
import { WishListProvider } from '../wishList/WishListProvider';

export type UserContextValue = {
    user: User | null;
    isUserFetched: boolean;
    isUserConnectedOnLoad: boolean;
    isUserConnected: boolean;
    fetchUser: () => Promise<User | null>;
};

export const UserContext = React.createContext<UserContextValue>({
    user: null,
    isUserFetched: false,
    isUserConnectedOnLoad: false,
    isUserConnected: false,
    fetchUser: () => Promise.resolve(null),
});

type UserProviderProps = {
    isConnectedOnLoad: boolean;
};

export function UserProvider(props: PropsWithChildren<UserProviderProps>) {
    const { generateUrl } = useUrlGenerator();
    const [user, setUser] = useState<User | null>(null);
    const [isUserFetched, setIsUserFetched] = useState(false);

    const apiUserUrl = generateUrl('api_user');

    const fetchUser = useCallback(async (): Promise<User | null> => {
        const response = await fetch(apiUserUrl, {
            credentials: 'same-origin',
        });

        if (!response.ok) {
            setIsUserFetched(true);
            throw new Error(`User request failed with status ${response.status}`);
        }

        const { user }: { user: User | null } = await response.json();

        setUser(user);
        setIsUserFetched(true);

        if (user) {
            const { userMD5 } = await getUserHash(user);
            trackCustomEvent({
                event: 'user_connected',
                user_md5: userMD5,
            });
            await setUserTrackingIdCookie(user);
        }

        return user;
    }, [apiUserUrl]);

    useEffect(() => {
        fetchUser().catch((error) => {
            logger.error('UserProvider user fetch failed on mount', {}, error);
        });
    }, [fetchUser]);

    return (
        <UserContext.Provider
            value={{
                user,
                isUserFetched,
                fetchUser,
                isUserConnectedOnLoad: props.isConnectedOnLoad,
                isUserConnected: isUserFetched && user !== null,
            }}
        >
            <WishListProvider>{props.children}</WishListProvider>
        </UserContext.Provider>
    );
}
