import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { useLocale } from '../../../core/i18n/Locale';
import { logger } from '../../../core/Log/logger';
import {
    addItineraryToWishList,
    getAllWishListItineraries,
    removeItineraryFromWishList,
} from '../../../resources/itinerary/api/pyrite/itinerary';
import { useUrlGenerator } from '../../hooks/useUrlGenerator';

import { useUser } from '../user/useUser';

type ExperienceWishFromData = {
    experience_id: number;
    destination_id: number;
};

export type ExperienceWish = {
    experienceId: number;
    destinationId: number;
};

export type ItineraryWish = number;

export type WishListToggleCallbackType = (id: number) => void;

export type WishListable = {
    onWishListAdd?: WishListToggleCallbackType;
    onWishListRemove?: WishListToggleCallbackType;
};

export interface WishList {
    itineraries: ItineraryWish[];
    experiences: ExperienceWish[];
}

interface WishListContextValue {
    fetchAll: () => Promise<void>;
    wishList: WishList;
    history: History;
    fetched: boolean;
    addExperience: (id: number, destinationId: number) => void;
    removeExperience: (id: number, destinationId: number) => void;
    addItinerary: (id: number) => void;
    removeItinerary: (id: number) => void;
}

export const WishListContext = React.createContext<WishListContextValue>({
    fetchAll: () => Promise.resolve(),
    wishList: {
        itineraries: [],
        experiences: [],
    },
    history: {
        itineraries: [],
        experiences: [],
    },
    fetched: false,
    addExperience: () => {
        return;
    },
    removeExperience: () => {
        return;
    },
    addItinerary: () => {
        return;
    },
    removeItinerary: () => {
        return;
    },
});

type History = {
    experiences: ExperienceWish[][];
    itineraries: ItineraryWish[][];
};

export function WishListProvider(props: PropsWithChildren<unknown>) {
    const { generatePath } = useUrlGenerator();
    const { locale } = useLocale();
    const { user } = useUser();
    const [fetched, setFetched] = useState(false);
    const [experiences, setExperiences] = useState<ExperienceWish[]>([]);
    const [itineraries, setItineraries] = useState<ItineraryWish[]>([]);
    const [history, setHistory] = useState<History>({ experiences: [], itineraries: [] });

    useEffect(() => {
        if (fetched) {
            setHistory((history) => {
                return {
                    ...history,
                    itineraries: [...history.itineraries, itineraries],
                };
            });
        }
    }, [itineraries, fetched]);

    useEffect(() => {
        if (fetched) {
            setHistory((history) => {
                return {
                    ...history,
                    experiences: [...history.experiences, experiences],
                };
            });
        }
    }, [experiences, fetched]);

    const fetchAll = useCallback(() => {
        async function fetchItinerariesWishList() {
            try {
                const itineraries = await getAllWishListItineraries({ locale });
                const itinerariesIds = itineraries.map((itinerary) => {
                    return itinerary.id;
                });

                setItineraries(itinerariesIds);
            } catch (error) {
                logger.error('Fetch WishListItineraries failed', { locale }, error);
            }
        }

        async function fetchExperiencesWishList() {
            const response = await fetch(generatePath('api_wishlist_experiences'));

            if (response.status !== 200) {
                return;
            }

            const data = await response.json();
            const experiencesFromResponse: ExperienceWishFromData[] = data.experiences || [];

            setExperiences(
                experiencesFromResponse.map((wishExperience) => {
                    return {
                        experienceId: wishExperience.experience_id,
                        destinationId: wishExperience.destination_id,
                    };
                }),
            );
        }

        setFetched(false);

        return Promise.all([fetchItinerariesWishList(), fetchExperiencesWishList()]).then(() => {
            setFetched(true);
        });
    }, [locale, generatePath]);

    const addExperience = useCallback(
        (id: number, destinationId: number) => {
            const body = new FormData();

            body.append('destination_id', destinationId.toString());
            body.append('experience_id', id.toString());

            setExperiences((experiences) => {
                return [...experiences, { experienceId: id, destinationId }];
            });

            fetch(generatePath('api_wishlist_experiences'), {
                method: 'POST',
                body,
                cache: 'no-store',
                credentials: 'same-origin',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error('Add experience failed');
                    }
                })
                .catch(() => {
                    setExperiences((experiences) => {
                        return experiences.filter((experience) => experience.experienceId !== id);
                    });
                });
        },
        [generatePath],
    );

    const removeExperience = useCallback(
        (id: number, destinationId: number) => {
            setExperiences((experiences) => {
                return experiences.filter((experience) => experience.experienceId !== id);
            });

            fetch(
                `${generatePath(
                    'api_wishlist_experiences',
                )}?destination_id=${destinationId.toString()}&experience_id=${id.toString()}`,
                {
                    method: 'DELETE',
                    cache: 'no-store',
                    credentials: 'same-origin',
                },
            )
                .then((response) => {
                    if (!response.ok) {
                        throw Error('Remove experience failed');
                    }
                })
                .catch(() => {
                    setExperiences((experiences) => {
                        return [...experiences, { experienceId: id, destinationId }];
                    });
                });
        },
        [generatePath],
    );

    const addItinerary = useCallback(
        (itineraryId: number) => {
            setItineraries((itineraries) => {
                return [...itineraries, itineraryId];
            });

            addItineraryToWishList({ locale, itineraryId }).catch((error) => {
                setItineraries((previousItineraries) => {
                    return previousItineraries.filter((id) => {
                        return id !== itineraryId;
                    });
                });

                logger.error('Add WishListItinerary failed', { locale, itineraryId }, error);
            });
        },
        [locale],
    );

    const removeItinerary = useCallback(
        (itineraryId: number) => {
            setItineraries((previousItineraries) => {
                return previousItineraries.filter((id) => {
                    return id !== itineraryId;
                });
            });

            removeItineraryFromWishList({ locale, itineraryId }).catch((error) => {
                setItineraries((previousItineraries) => {
                    return [...previousItineraries, itineraryId];
                });

                logger.error('Remove WishListItinerary failed', { locale, itineraryId }, error);
            });
        },
        [locale],
    );

    useEffect(() => {
        if (user !== null) {
            void fetchAll();
        }
    }, [fetchAll, user]);

    return (
        <WishListContext.Provider
            value={{
                fetchAll,
                wishList: { itineraries, experiences },
                history,
                fetched,
                addExperience,
                removeExperience,
                addItinerary,
                removeItinerary,
            }}
        >
            {props.children}
        </WishListContext.Provider>
    );
}
