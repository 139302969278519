import { useContext } from 'react';

import { CurrentSiteContext } from './CurrentSiteProvider';

export const useCurrentSite = () => {
    const currentSiteValue = useContext(CurrentSiteContext);

    if (!currentSiteValue) {
        throw new Error('useCurrentSite hook must be used in a CurrentSiteProvider');
    }
    return currentSiteValue;
};
