import { md5 } from '../../../../shared/utilities/crypto/md5';
import { sha256 } from '../../../../shared/utilities/crypto/sha256';
import { PickDomain } from '../../../../shared/utilities/types/PickDomain';
import { User } from '../types/User';

export const getUserHash = async (
    user: PickDomain<User, 'email'>,
): Promise<{ userMD5: string; userSHA256: string }> => {
    return {
        userMD5: md5(user.email),
        userSHA256: await sha256(user.email),
    };
};
