declare global {
    interface Window {
        dataLayer: Array<unknown>;
    }
}

export function trackCustomEvent(event: CustomTrackEvent) {
    if (typeof window === 'undefined') {
        return;
    }
    window.dataLayer.push(event);
}

type CustomTrackEvent = {
    event: string;
    [key: string]: string | Record<string, unknown>;
};
