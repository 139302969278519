import { handleResponse } from '../../../../core/Api/Pyrite/handlers';
import { Locale } from '../../../../core/i18n/Locale';
import { generateUrl } from '../../../../shared/utilities/routing/routing';
import { Itinerary } from '../../domain/types/Itinerary';

import { normalizeWishListItinerary, WishListItineraryFromPyrite } from './normalizers';

async function fetchAllWishListItineraries({
    locale,
}: {
    locale: Locale;
}): Promise<Array<WishListItineraryFromPyrite>> {
    if (typeof window === 'undefined') {
        throw new Error('Cannot fetch WishListItineraries on server side');
    }

    const response = await fetch(generateUrl('api_wishlist_itineraries', locale));

    return await handleResponse<Array<WishListItineraryFromPyrite>>(response);
}

export async function getAllWishListItineraries({ locale }: { locale: Locale }) {
    const itineraries = await fetchAllWishListItineraries({ locale });

    return itineraries.map((itinerary) => {
        return normalizeWishListItinerary(itinerary);
    });
}

export async function addItineraryToWishList({
    locale,
    itineraryId,
}: {
    locale: Locale;
    itineraryId: Itinerary['id'];
}) {
    if (typeof window === 'undefined') {
        throw new Error('Cannot add WishListItinerary on server side');
    }

    const response = await fetch(generateUrl('api_wishlist_itineraries', locale), {
        method: 'POST',
        body: `itineraryId=${itineraryId}`,
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
    });

    return handleResponse<'success'>(response);
}

export async function removeItineraryFromWishList({
    locale,
    itineraryId,
}: {
    locale: Locale;
    itineraryId: Itinerary['id'];
}) {
    if (typeof window === 'undefined') {
        throw new Error('Cannot remove WishListItinerary on server side');
    }

    const response = await fetch(generateUrl('api_wishlist_itineraries', locale), {
        method: 'DELETE',
        body: `itineraryId=${itineraryId}`,
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
    });

    return handleResponse<'success'>(response);
}
