import { ImageLoaderProps } from 'next/image';

import { assetCdnUrl, pyriteStaticPath, staticCdnUrl } from '../../../../core/Assets/Assets';
import { formatByRatioAndWidth, MagazineRatio } from '../../../../core/Assets/Image/MagazineImage';
import { RATIO } from '../../../../design-system/components/ratio/Ratio';
import { Picture, PICTURE_ORIGIN, PictureWithMultipleUrl } from '../../../Picture/Picture';

type EvaneosCdnMethod = 'crop' | 'resize';
type OfferCdnFit = 'crop' | 'cover' | 'contain';
type PublicWebsiteContentCdnFit = 'crop' | 'cover' | 'contain';

export const PLACEHOLDER_PICTURE_ID = 0;

export function imageLoaderForPath({ src: imagePath }: ImageLoaderProps) {
    return pyriteStaticPath(`varanasi/assets/images${imagePath}`);
}

export function svgUrlForPath(svgPath: string) {
    return pyriteStaticPath(`varanasi/assets/svgs${svgPath}`);
}

export function imageLoaderForId(
    { aspectRatio, fixedHeight, method }: PropsLoaderForId = {
        aspectRatio: RATIO['16/9'],
    },
) {
    return ({ src: imageId, width, quality }: ImageLoaderProps) => {
        const height = fixedHeight ?? Math.round(width / aspectRatio);

        return imageUrlForStaticCdn(imageId, width, height, quality, method);
    };
}

export function imageLoaderForPicture(
    picture: Picture,
    { aspectRatio = RATIO['16/9'], fixedHeight, method }: PropsLoaderForId,
) {
    return ({ width, quality }: ImageLoaderProps) => {
        if (picture.origin === 'dam_image') {
            throw new Error('Use imageLoaderForDamImages for Picture with origin "dam_image"');
        }

        const height = fixedHeight ?? Math.round(width / aspectRatio);

        return imageUrlForCdn(picture, width, height, quality, method);
    };
}

export function imageLoaderForPictureWithMultipleUrl(picture: PictureWithMultipleUrl) {
    return ({ width }: ImageLoaderProps) => {
        let [validUrl] = picture.urls;
        const urlSortArray = picture.urls.toSorted((a, b) => a.width - b.width);
        for (let i = 1; i < urlSortArray.length; i++) {
            const urlToCheck = urlSortArray[i];
            const isCurrentValidUrlTooShort = validUrl.width < width;
            if (urlToCheck.width > validUrl.width && isCurrentValidUrlTooShort) {
                validUrl = urlSortArray[i];
            }
            if (urlToCheck.width > width) {
                break;
            }
        }

        return validUrl?.url ?? '';
    };
}

export function imageLoaderForDamImages({ aspectRatio }: PropsLoaderForArticle) {
    return ({ src: imageId, width }: ImageLoaderProps) => {
        const format = formatByRatioAndWidth(aspectRatio, width);

        return assetCdnUrl(`dam-images/${imageId}/${aspectRatio}/${format}.jpeg`);
    };
}

export function imageLoaderForPimAssets({ src }: ImageLoaderProps) {
    return imageUrlForPimAssets(src);
}

export function imageUrlForPimAssets(src: string) {
    return assetCdnUrl(`pim-assets-images/${src}`);
}

export function imageUrlForCdn(
    picture: Picture,
    width: number,
    height: number,
    quality = 70,
    method: OfferCdnFit | EvaneosCdnMethod | PublicWebsiteContentCdnFit = 'crop',
) {
    if (picture.origin === PICTURE_ORIGIN.offerManager) {
        return imageUrlForOfferCdn(picture.id, width, height, quality, method as OfferCdnFit);
    }

    if (picture.origin === PICTURE_ORIGIN.publicWebsiteContent) {
        return imageUrlForPublicWebsiteContentCdn(
            picture.id,
            width,
            height,
            quality,
            method as PublicWebsiteContentCdnFit,
        );
    }

    return imageUrlForStaticCdn(picture.id, width, height, quality, method as EvaneosCdnMethod);
}

export function imageUrlForStaticCdn(
    imageId: number | string,
    width: number,
    height: number,
    quality = 70,
    method: EvaneosCdnMethod = 'crop',
) {
    return staticCdnUrl(
        `images/reduction/${imageId}_w-${width}_h-${height}_q-${quality}_m-${method}.jpg`,
    );
}

/**
 * Offer CDN is a Cloudflare Image Resizing service that allows us to resize images on the fly.
 * https://developers.cloudflare.com/images/image-resizing/url-format/
 */
export function imageUrlForOfferCdn(
    path: string,
    width: number,
    height: number,
    quality = 70,
    method: OfferCdnFit = 'crop',
) {
    return staticCdnUrl(
        `cdn-cgi/image/width=${width},height=${height},quality=${quality},fit=${method}/offer/raw/${path}`,
    );
}

/**
 * Public website content CDN is a Cloudflare Image Resizing service that allows us to resize images on the fly.
 * https://developers.cloudflare.com/images/image-resizing/url-format/
 */
export function imageUrlForPublicWebsiteContentCdn(
    path: string,
    width: number,
    height: number,
    quality = 70,
    method: PublicWebsiteContentCdnFit = 'crop',
) {
    return staticCdnUrl(
        `cdn-cgi/image/width=${width},height=${height},quality=${quality},fit=${method}/content/${path}`,
    );
}

interface PropsLoaderForArticle {
    aspectRatio: MagazineRatio;
}

type PropsLoaderForIdWithAspectRatio = {
    aspectRatio: number;
    fixedHeight?: never;
};

type PropsLoaderForIdWithFixedHeight = {
    aspectRatio?: never;
    fixedHeight: number;
};

type PropsLoaderForId = { method?: EvaneosCdnMethod } & (
    | PropsLoaderForIdWithAspectRatio
    | PropsLoaderForIdWithFixedHeight
);
