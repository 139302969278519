import { GetServerSidePropsContext, NextPageContext } from 'next';

import localesConfig from '../../configurations/locales.json';
import { useCurrentSite } from '../../shared/providers/currentSite/useCurrentSite';
import { getSiteByLocale, getSiteByLocaleEvaneos, getSiteByLocalePrismic } from '../Site/Site';

export type Locale = keyof typeof localesConfig.all;

export type LocaleEvaneos = keyof typeof localesConfig.allEvaneos;

export type LocalePrismic = keyof typeof localesConfig.allPrismic;

const ALL_LOCALES = Object.keys(localesConfig.all) as Array<Locale>;

export function useLocale() {
    const { currentSite } = useCurrentSite();

    return {
        locale: currentSite.locale,
        localeEvaneos: currentSite.localeEvaneos,
    };
}

export function getLocaleFromLocaleEvaneos(localeEvaneos: LocaleEvaneos): Locale {
    return getSiteByLocaleEvaneos(localeEvaneos).locale;
}

export function getLocaleEvaneosFromLocale(locale: Locale): LocaleEvaneos {
    return getSiteByLocale(locale).localeEvaneos;
}

export function getLocalePrismicFromLocale(locale: Locale): LocalePrismic {
    return getSiteByLocale(locale).localePrismic;
}

export function getLocaleFromLocalePrismic(localePrismic: LocalePrismic): Locale {
    return getSiteByLocalePrismic(localePrismic).locale;
}

export function getLocaleFromContext(context: GetServerSidePropsContext | NextPageContext): Locale {
    if (!context.locale) {
        throw new Error(`Context missing locale`);
    }
    return context.locale as Locale;
}

export function getAllLocales(): Array<Locale> {
    return ALL_LOCALES;
}

export function isLocale(locale: string): locale is Locale {
    return getAllLocales().includes(locale as Locale);
}

export function getDefaultLocale() {
    return localesConfig.default as Locale;
}

export function getDefaultLocaleFromContext(
    context: GetServerSidePropsContext | NextPageContext,
): Locale {
    if (!context.defaultLocale) {
        throw new Error(`Context missing defaultLocale`);
    }
    return context.defaultLocale as Locale;
}
