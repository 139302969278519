'use client';

import React, { PropsWithChildren, useMemo } from 'react';

interface PageContextValue {
    seoContext: string;
    newPrimaryNavEnabled: boolean;
}

export const PageContext = React.createContext<PageContextValue>({
    seoContext: '',
    newPrimaryNavEnabled: false,
});

export const PageProvider = ({
    children,
    seoContext,
    newPrimaryNavEnabled,
}: PropsWithChildren<PageContextValue>) => {
    const value = useMemo(() => {
        return {
            seoContext,
            newPrimaryNavEnabled,
        };
    }, [seoContext, newPrimaryNavEnabled]);

    return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};
