import { browserLogger } from './browserLogger';

export const logger = {
    error: logError,
    warning: logWarning,
    info: logInfo,
};

function logError(
    message: string,
    context: {
        [key: string]: unknown;
        error?: never;
    },
    error?: unknown,
): void {
    const loggerContext = { ...context, error };
    const errorInstance = error instanceof Error ? error : undefined;

    if (process.env.NODE_ENV === 'development') {
        console.error(message, loggerContext, errorInstance);
    }

    if (isServer()) {
        return void import('./serverLogger').then(({ serverLogger }) => {
            serverLogger.error(loggerContext, message);
        });
    }

    return browserLogger.error(message, loggerContext, errorInstance);
}

function logWarning(message: string, context: Record<string, unknown>) {
    if (process.env.NODE_ENV === 'development') {
        console.warn(message, context);
    }

    if (isServer()) {
        return void import('./serverLogger').then(({ serverLogger }) => {
            serverLogger.warn(context, message);
        });
    }

    return browserLogger.warn(message, context);
}

function logInfo(message: string, context: Record<string, unknown>) {
    if (process.env.NODE_ENV === 'development') {
        console.info(message, context);
    }

    if (isServer()) {
        return void import('./serverLogger').then(({ serverLogger }) => {
            serverLogger.info(context, message);
        });
    }

    return browserLogger.info(message, context);
}

function isServer() {
    return typeof window === 'undefined';
}
