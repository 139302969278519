import { NotFoundResponse } from '../../../server/response/NotFoundResponse';

import { responseError } from '../ResponseError';

export async function handleResponse<Data>(response: Response) {
    if (response.status === 404) {
        throw NotFoundResponse.error(`404 Not Found from ${response.url}`);
    }

    if (response.status !== 200) {
        throw await responseError(response);
    }

    return (await response.json()) as Promise<Data>;
}
