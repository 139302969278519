'use client';

import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { Locale } from '../../../core/i18n/Locale';
import { Site } from '../../../core/Site/Site';
import { useSite } from '../../hooks/useSite';

type CurrentSiteContextValue = { currentSite: Site } | null;

export const CurrentSiteContext = createContext<CurrentSiteContextValue>(null);

export const CurrentSiteProvider = ({
    children,
    locale,
}: PropsWithChildren<{ locale: Locale }>) => {
    const currentSite = useSite(locale);
    const value = useMemo(() => {
        return {
            currentSite,
        };
    }, [currentSite]);

    return <CurrentSiteContext.Provider value={value}>{children}</CurrentSiteContext.Provider>;
};
