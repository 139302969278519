'use client';

import { PickDomain } from '../../../../shared/utilities/types/PickDomain';
import { User } from '../types/User';

import { getUserHash } from './getUserHash';

/**
 * Used for the reconciliation of users for Facebook and Google advertisements
 */
export const setUserTrackingIdCookie = async (user: PickDomain<User, 'email'>) => {
    if (typeof document === 'undefined') {
        throw new Error('Cannot set user tracking id cookie on server side');
    }

    const { userMD5, userSHA256 } = await getUserHash(user);

    const TWO_YEARS_IN_MS = 365 * 2 * 24 * 60 * 60 * 1000;
    const expirationDate = new Date(Date.now() + TWO_YEARS_IN_MS);

    document.cookie = `user_md5=${userMD5}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = `user_hash=${userSHA256}; expires=${expirationDate.toUTCString()}; path=/`;
};
