import { useCallback, useEffect, useState } from 'react';

const SM_MIN_WIDTH = 576;
const MD_MIN_WIDTH = 768;
const LG_MIN_WIDTH = 992;
const XL_MIN_WIDTH = 1200;
const XXL_MIN_WIDTH = 1400;

export type BreakPointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{
        width?: number;
        height?: number;
    }>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export function useBreakPoint() {
    const { width } = useWindowSize();

    const breakPoints = {
        isXs: useCallback(() => {
            if (!width) {
                return false;
            }
            return width < SM_MIN_WIDTH;
        }, [width]),
        isSm: useCallback(() => {
            if (!width) {
                return false;
            }
            return width >= SM_MIN_WIDTH && width < MD_MIN_WIDTH;
        }, [width]),
        isMd: useCallback(() => {
            if (!width) {
                return false;
            }
            return width >= MD_MIN_WIDTH && width < LG_MIN_WIDTH;
        }, [width]),
        isLg: useCallback(() => {
            if (!width) {
                return false;
            }
            return width >= LG_MIN_WIDTH && width < XL_MIN_WIDTH;
        }, [width]),
        isXl: useCallback(() => {
            if (!width) {
                return false;
            }
            return width >= XL_MIN_WIDTH && width < XXL_MIN_WIDTH;
        }, [width]),
        isXxl: useCallback(() => {
            if (!width) {
                return false;
            }
            return width >= XXL_MIN_WIDTH;
        }, [width]),
    };

    const breakPointsState: Array<{ name: BreakPointName; isCurrent: boolean }> = [
        { name: 'xs', isCurrent: breakPoints.isXs() },
        { name: 'sm', isCurrent: breakPoints.isSm() },
        { name: 'md', isCurrent: breakPoints.isMd() },
        { name: 'lg', isCurrent: breakPoints.isLg() },
        { name: 'xl', isCurrent: breakPoints.isXl() },
        { name: 'xxl', isCurrent: breakPoints.isXxl() },
    ];

    const currentBreakpoint: BreakPointName = breakPointsState.reduce(
        (currentBreakPointName, breakPoint) => {
            const { name, isCurrent } = breakPoint;
            if (isCurrent) {
                return name;
            }
            return currentBreakPointName;
        },
        'xs' as BreakPointName,
    );

    const { isXs, isSm, isMd, isLg, isXl, isXxl } = breakPoints;

    const isLowerThanXL = useCallback(() => {
        if (!width) {
            return false;
        }
        return width < XL_MIN_WIDTH;
    }, [width]);

    const isDesktop = useCallback(() => {
        return isLg() || isXl() || isXxl();
    }, [isLg, isXl, isXxl]);

    const isMobile = useCallback(() => {
        return isXs() || isSm() || isMd();
    }, [isXs, isSm, isMd]);

    return {
        ...breakPoints,
        currentBreakpoint,
        isDesktop,
        isMobile,
        isLowerThanXL,
    };
}
