export type Picture = {
    origin: PictureOrigin;
    id: string;
    author: string;
    legend: string;
};

export const PICTURE_ORIGIN = {
    publicPicture: 'public_pictures',
    prismicPicture: 'prismic_picture',
    offerManager: 'offer_manager',
    publicWebsiteContent: 'public_website_content',
    damImage: 'dam_image',
} as const;

export type PictureOrigin = (typeof PICTURE_ORIGIN)[keyof typeof PICTURE_ORIGIN];

export type PictureWithUrl = {
    url: string;
    author: string;
    legend: string;
};

export type PictureWithMultipleUrl = {
    origin: PictureOrigin;
    urls: Array<{ url: string; width: number; height: number }>;
    author: string;
    legend: string;
};

export function defaultPicture() {
    return {
        origin: PICTURE_ORIGIN.publicPicture,
        id: '0',
        author: '',
        legend: '',
    };
}
