'use client';

import { ParsedUrlQuery } from 'querystring';

import { Identify, Types } from '@amplitude/analytics-browser';
import { EnrichmentPlugin, Event } from '@amplitude/analytics-types';
import { Experiment, ExperimentClient, Variant } from '@amplitude/experiment-js-client';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useRouter } from 'next/router';
import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useBreakPoint } from '../../../domains/Layout/BreakPoint/BreakPoint';
import { usePage } from '../../../shared/providers/page/usePage';
import { useUser } from '../../../shared/providers/user/useUser';
import { logger } from '../../Log/logger';

import { Ampli, ampli, ApiKey, Environment } from './__AUTO_GENERATED__';

const QUERY_PARAMS_TO_IGNORE = ['jwt', 'email'];

export type TrackingProperties<F extends (properties: Parameters<F>[0]) => void> = Omit<
    Parameters<F>[0],
    keyof ReturnType<typeof getDefaultTrackingProperties>
>;

type AssignationData = Record<string, string>;

function getAmplitudeApiKey(): string | null {
    const env = process.env.NEXT_PUBLIC_AMPLITUDE_ENV;

    if (!env) {
        return null;
    }

    return ApiKey[env as Environment];
}

function getExperimentDeployKey(): string {
    const deployKey = process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_DEPLOY_KEY;

    if (!deployKey) {
        throw new Error('Amplitude Experiment missing a deploy key');
    }

    return deployKey;
}

const enrichPageUrlPlugin: EnrichmentPlugin = {
    name: 'enrich-page-url-plugin',
    type: 'enrichment',
    async execute(event: Event) {
        if (event.event_type === 'session_start') {
            //@ts-expect-error - Property '$skip_user_properties_sync' is not included in the Event type
            event.$skip_user_properties_sync = true;
        }

        return event;
    },
};

type AmplitudeContextValue = {
    setIsConnected: (isConnected: boolean) => void;
    isAmplitudeEnabled: boolean;
    experiment: ExperimentClient | null;
    client: Types.BrowserClient | null;
};

const AmplitudeContext = React.createContext<AmplitudeContextValue>({
    setIsConnected: () => {
        return;
    },
    isAmplitudeEnabled: false,
    experiment: null,
    client: null,
});

export const AmplitudeProvider = (
    props: PropsWithChildren<{
        isConnectedOnLoad: boolean;
    }>,
) => {
    const [hasConsented, setHasConsented] = useState(false);
    const [isAmplitudeLoaded, setIsAmplitudeLoaded] = useState(ampli.isLoaded);
    const isAmplitudeEnabled = hasConsented && isAmplitudeLoaded;
    const experiment = useRef<ExperimentClient | null>(null);
    const { user, isUserFetched } = useUser();
    const { isMobile } = useBreakPoint();
    const identifyObjRef = useRef(new Identify());

    const amplitudeApiKey = getAmplitudeApiKey();

    /** Manually set the isConnected user property for instant availability of the information in amplitude tracking */
    const setIsConnected = useCallback(
        (isConnected: boolean) => {
            if (ampli.client) {
                identifyObjRef.current.set('isConnected', isConnected);
                if (isConnected) {
                    identifyObjRef.current.set('is_evaneos', user?.isEvaneos ?? false);
                    if (user?.status) {
                        identifyObjRef.current.set('user_status', user.status);
                    }
                    identifyObjRef.current.set(
                        'ab_reco_available',
                        user?.recommendationsIsEnabled ?? false,
                    );
                }
                ampli.client.identify(identifyObjRef.current);
            }
        },
        [user?.recommendationsIsEnabled, user?.isEvaneos, user?.status],
    );

    const setDefaultIdentifiers = useCallback(() => {
        if (ampli.client) {
            identifyObjRef.current.set('device_category', isMobile() ? 'mobile' : 'desktop');
            identifyObjRef.current.set('isConnected', props.isConnectedOnLoad);
            ampli.client.identify(identifyObjRef.current);
        }
    }, [isMobile, props.isConnectedOnLoad]);

    useEffect(() => {
        if (isUserFetched && isAmplitudeLoaded) {
            const userIsConnected = user !== null;
            setIsConnected(userIsConnected);
        }
    }, [isAmplitudeLoaded, isUserFetched, setIsConnected, user]);

    if (amplitudeApiKey && !isAmplitudeLoaded && hasConsented) {
        ampli
            .load({
                client: {
                    configuration: {
                        serverZone: 'EU',
                        defaultTracking: {
                            pageViews: false,
                            sessions: true,
                            attribution: true,
                            fileDownloads: false,
                            formInteractions: false,
                        },
                    },
                    apiKey: amplitudeApiKey,
                },
            })
            .promise.then(() => {
                setDefaultIdentifiers();

                ampli.client.add(enrichPageUrlPlugin);

                if (process.env.NEXT_PUBLIC_AMPLITUDE_ENV === 'production') {
                    ampli.client.add(
                        sessionReplayPlugin({
                            sampleRate: 0.05,
                        }),
                    );
                }

                import('@amplitude/engagement-browser')
                    .then(({ plugin }) => {
                        ampli.client.add(plugin());
                    })
                    .catch((error) => {
                        logger.error('Amplitude Engagement plugin failed to load', {}, error);
                    });

                if (!experiment.current) {
                    experiment.current = Experiment.initializeWithAmplitudeAnalytics(
                        getExperimentDeployKey(),
                        {
                            serverUrl: 'https://api.lab.eu.amplitude.com',
                        },
                    );
                }

                setIsAmplitudeLoaded(true);
                window.amplitude = ampli.client;
            })
            .catch((error) => {
                logger.error('Amplitude load failed', {}, error);
            });
    }

    useEffect(() => {
        window.didomiEventListeners = window.didomiEventListeners || [];
        window.didomiEventListeners.push({
            event: 'consent.changed',
            listener() {
                if (window.Didomi?.getUserConsentStatusForVendor('c:amplitude')) {
                    setHasConsented(true);
                } else {
                    setHasConsented(false);
                }
            },
        });

        window.didomiOnReady = window.didomiOnReady || [];
        window.didomiOnReady.push(() => {
            if (window.Didomi?.getUserConsentStatusForVendor('c:amplitude')) {
                setHasConsented(true);
            }
        });
    }, []);

    useEffect(() => {
        if (isAmplitudeEnabled && user) {
            ampli.identify(user.accountId);
        }
    }, [isAmplitudeEnabled, user]);

    const value = useMemo(() => {
        return {
            setIsConnected,
            isAmplitudeEnabled,
            client: isAmplitudeLoaded ? ampli.client : null,
            experiment: experiment.current,
        };
    }, [isAmplitudeEnabled, isAmplitudeLoaded, setIsConnected]);

    return <AmplitudeContext.Provider value={value}>{props.children}</AmplitudeContext.Provider>;
};

function getCanonicalUrl() {
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
        return canonical.getAttribute('href');
    }

    return null;
}

function removeIgnoredQueryParams(url: URL) {
    QUERY_PARAMS_TO_IGNORE.forEach((element) => {
        url.searchParams.delete(element);
    });
    return url;
}

function parseUrl(urlToParse: string) {
    const url = document.createElement('a');
    url.href = urlToParse;

    return url.pathname;
}

function getReferrer() {
    return document.referrer || '$direct';
}

function getReferringDomain(referrer: string) {
    const split = referrer.split('/');
    if (split.length >= 3) {
        return split[2];
    }

    return '';
}

function getUrl(search: string) {
    const canonicalUrl = getCanonicalUrl();
    const url = canonicalUrl
        ? canonicalUrl.indexOf('?') > -1
            ? canonicalUrl
            : canonicalUrl + search
        : window.location.href;
    const hashIndex = url.indexOf('#');

    return hashIndex > -1 ? url.slice(0, hashIndex) : url;
}

function getHostname() {
    return window.location.hostname;
}

function getDefaultTrackingProperties(seoContext: string) {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
        return {
            category: '',
            path: '',
            referrer: '',
            search: '',
            title: '',
            url: '',
            referring_domain: '',
            tab_url: '',
            site_name: '',
        };
    }

    const canonicalUrl = getCanonicalUrl();
    const sanitizedLocation = removeIgnoredQueryParams(new URL(window.location.href));
    const path = canonicalUrl ? parseUrl(canonicalUrl) : sanitizedLocation.pathname;
    const { search } = sanitizedLocation;
    const { title } = document;
    const url = getUrl(search);
    const tabUrl = sanitizedLocation.href;
    const referrer = getReferrer();
    const referringDomain = getReferringDomain(referrer);
    const siteName = getHostname();

    return {
        category: seoContext,
        path,
        referrer,
        search,
        title,
        url,
        referring_domain: referringDomain,
        tab_url: tabUrl,
        site_name: siteName,
    };
}

export const useAmplitude = () => {
    return useContext(AmplitudeContext);
};

/**
 * Custom hook to fetch and return the variant of an experiment using Amplitude.
 *
 * @param experimentName - The name of the experiment to fetch the variant for.
 * @param assignationData - Optional data used for experiment assignations. `site_name` and `page` are automatically injected.
 * @returns The variant of the experiment.
 *
 * This hook utilizes the Amplitude SDK to fetch the variant of a given experiment.
 * It first checks if Amplitude is enabled and if the experiment object is available.
 * It then fetches the experiment with the user properties merged with the assignation data.
 * Once the fetch is complete, it sets the variant of the experiment.
 *
 * @example
 * const experiment = useExperimentVariation('my-experiment', { destination_id: '28' });
 * const variant = experiment?.value;
 */
export const useExperimentVariation = (
    experimentName: string,
    assignationData?: AssignationData,
) => {
    const { isAmplitudeEnabled, experiment } = useAmplitude();
    const { seoContext } = usePage();
    const [variant, setVariant] = useState<Variant>();
    const refAssignationData = useRef(assignationData);

    const router = useRouter();
    const { query } = router;

    useEffect(() => {
        void fetchExperimentVariation(
            isAmplitudeEnabled,
            experiment,
            seoContext,
            experimentName,
            refAssignationData.current,
            query,
        ).then((variant) => {
            if (variant) {
                setVariant(variant);
            }
        });
    }, [isAmplitudeEnabled, experiment, experimentName, seoContext, query]);

    return variant;
};

export function fetchExperimentVariation(
    isAmplitudeEnabled: boolean,
    experiment: ExperimentClient | null,
    seoContext: string,
    experimentName: string,
    assignationData?: AssignationData,
    query?: ParsedUrlQuery,
): Promise<Variant | undefined> {
    const hasExperimentQueryparam =
        query && query.withExperimentName === experimentName && query.withExperimentVariation;

    if (!hasExperimentQueryparam && isAmplitudeEnabled && experiment) {
        return experiment
            .fetch({
                // Only used for experiment assignations
                user_properties: {
                    ...experiment.getUser()?.user_properties,
                    page: seoContext,
                    site_name: getHostname(),
                    ...assignationData,
                },
            })
            .then(() => {
                return experiment.variant(experimentName);
            });
    }
    if (hasExperimentQueryparam) {
        // Example: ?withExperimentName=<myExperimentName>&withExperimentVariation=<myExperimentVariation>
        return Promise.resolve({ value: query.withExperimentVariation as string });
    }
    return Promise.resolve(undefined);
}

export const useTrackPageViewAmplitude = (
    callback: (
        ampli: Ampli,
        defaultProperties: ReturnType<typeof getDefaultTrackingProperties>,
    ) => void,
) => {
    const { isAmplitudeEnabled } = useAmplitude();
    const callbackRef = useRef(callback);
    const { seoContext } = usePage();

    useEffect(() => {
        if (isAmplitudeEnabled) {
            callbackRef.current(ampli, getDefaultTrackingProperties(seoContext));
        }
    }, [isAmplitudeEnabled, seoContext]);
};

export type TrackEventType = (
    callback: (
        ampli: Ampli,
        defaultProperties: ReturnType<typeof getDefaultTrackingProperties>,
    ) => void,
) => void;

export const useTrackEventAmplitude = () => {
    const { isAmplitudeEnabled } = useAmplitude();
    const { seoContext } = usePage();

    const trackEvent: TrackEventType = useCallback(
        (callback) => {
            if (isAmplitudeEnabled) {
                callback(ampli, getDefaultTrackingProperties(seoContext));
            }
        },
        [isAmplitudeEnabled, seoContext],
    );

    return {
        trackEvent,
    };
};
